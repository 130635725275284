/*
 * @Author: your name
 * @Date: 2021-11-13 10:50:51
 * @LastEditTime: 2021-11-16 18:00:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MedicineDiagnosis.Management.Web\src\components\Healthy\Questions\Questions.js
 */
let Questions = function(tokenClient,serverAddr){
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serverAddr;

    this.GetOrgUserQuestionnaireAll = function (keyWord, pageIndex, success, error) {
        var url = ServiceBaseAddress + `/api/Questionnaire/GetOrgUserQuestionnaireAll`;
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        TokenClient.Get(url,true,null,null,
            function(data){
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            },error);
    }
    
}

export {Questions}
