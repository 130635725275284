<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" :inline="true">
                            <el-form-item label="">
                                <el-input v-model="Keyword" type="text" size="small" placeholder="测试用户/账号"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 80px)" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="customerName" label="测试用户">
                    </el-table-column>
                    <el-table-column prop="customerPhone" label="测试用户账号" align="center">
                    </el-table-column>
                    <el-table-column prop="questionnaire.category" label="试题类型" align="center">
                    </el-table-column>
                    <el-table-column prop="questionnaire.name" label="试题名称" align="center">
                    </el-table-column>
                    <el-table-column prop="questionnaire.summarized" label="测试结果" align="center">
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { Questions } from '../../../components/STATS/Questions.js';
import bread from "../../../components/common/breadcrumb";

export default {
    name: "OwnStats",
    components: {
        bread
    },
    data() {
        var questions = new Questions(this.TokenClient, this.Services.Healthy);
        return {
            QuestionsDomain: questions,
            tableData: [],
            Keyword: "",
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            this.QuestionsDomain.GetOrgUserQuestionnaireAll( this.Keyword,this.PageIndex,
                (data) => {
                    this.tableData = data.data.results;
                    this.PageIndex = data.data.pageIndex;
                    this.PageSize = data.data.pageSize;
                    this.PageTotal = data.data.pageCount;
                    this.DataTotal = data.data.dataTotal;
                }, (err) => {
                    console.log(err);
                })
        },
        DetailBtn(row) {
            this.$router.push({
                name: "SalesDetail",
                query: {
                    commodityId: row.id
                }
            })
        }
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    /* width: 480px; */
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}
</style>
